import { SchoolPaceStats } from 'api/schoolpace/get-stats'
import axios from 'axios'
import { IStats } from 'components/digital-solutions/Stats'
import { Section, Row, Col } from 'components/grid'
import { graphql, useStaticQuery } from 'gatsby'
import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image'
import React, { useEffect, useState } from 'react'
import CountUp from 'react-countup'
import VisibilitySensor from 'react-visibility-sensor'
import { twMerge } from 'tailwind-merge'

export default function ImpactStats() {
	const data = useStaticQuery<Query>(query).allContentfulAsset.edges[0].node.gatsbyImageData
	const [stats, setStats] = useState<IStats>({ districts: 863, schools: 4097, classrooms: 46141, students: 2126539, states: 45 })

	useEffect(() => {
		async function getSchoolPaceStats() {
			try {
				const { data } = await axios.get<SchoolPaceStats>('/api/schoolpace/get-stats')
				if (typeof data !== 'object') throw new Error('Something went wrong getting schoolpace stats')
				setStats({ districts: data.d, schools: data.s, classrooms: data.c, students: data.st, states: data.sp })
			} catch (e: any) {
				setStats({ districts: 863, schools: 4097, classrooms: 46141, students: 2126539, states: 45 })
				if (axios.isAxiosError(e)) {
					console.log(e.response?.data)
				} else console.log(e.message)
			}
		}
		getSchoolPaceStats()
	}, [])

	return (
		<Section className="py-20">
			<Row>
				<Col className="px-0">
					<div className="relative">
						<GatsbyImage image={data} alt="arc careers collage" />
						<div className="absolute top-0 left-0 flex h-full w-full items-center justify-center">
							<div>
								<h3
									className="text-center font-inter text-5xl font-extrabold text-white lg:text-6xl xl:text-8xl"
									style={{ textShadow: '3px 3px 5px rgba(0, 0, 0, 0.4)' }}
								>
									Our Impact
								</h3>
							</div>
						</div>
					</div>
					<div className="relative z-10 mx-auto -mt-6 block w-11/12 flex-wrap justify-center gap-6 border bg-white p-4 shadow-lg md:flex lg:-mt-20 lg:justify-between lg:p-8">
						<div className="mb-4 flex flex-col items-center justify-center md:mb-0">
							<CountUpNumber number={stats.students} RenderComponent={<GradientNumber colors="from-blue-500 to-purple-500" />} />
							<span className="text-xl lg:text-2xl">Students</span>
						</div>
						<div className="mb-4 flex flex-col items-center justify-center md:mb-0">
							<CountUpNumber number={stats.classrooms} RenderComponent={<GradientNumber colors="from-rose-400 to-red-500" />} />
							<span className="text-xl lg:text-2xl">Classrooms</span>
						</div>
						<div className="mb-4 flex flex-col items-center justify-center md:mb-0">
							<CountUpNumber number={stats.schools} RenderComponent={<GradientNumber colors="from-red-400 to-orange-500" />} />
							<span className="text-xl lg:text-2xl">Schools</span>
						</div>
						<div className="mb-4 flex flex-col items-center justify-center md:mb-0">
							<CountUpNumber number={stats.districts} RenderComponent={<GradientNumber colors="from-orange-400 to-yellow-500" />} />
							<span className="text-xl lg:text-2xl">Districts</span>
						</div>
					</div>
				</Col>
			</Row>
		</Section>
	)
}

interface GradientNumberProps {
	colors: string
}

type CustomSpanProps = React.HTMLProps<HTMLSpanElement> & GradientNumberProps

const GradientNumber = React.forwardRef<HTMLSpanElement, CustomSpanProps>(({ colors, ...props }, ref) => (
	<span ref={ref} className={twMerge('mb-0 bg-gradient-to-r bg-clip-text text-5xl font-extrabold text-transparent lg:text-6xl', colors)} {...props} />
))

interface CountUpNumberProps {
	number: number
	RenderComponent: React.ReactElement
}

function CountUpNumber({ number, RenderComponent }: CountUpNumberProps) {
	const [active, setActive] = useState(true)
	function onVisibilityChange(isVisible: boolean, start: () => void) {
		if (isVisible && active) {
			start()
			setActive(false)
		}
	}
	return (
		<CountUp end={number} separator="," duration={2}>
			{({ countUpRef, start }) => (
				<VisibilitySensor onChange={(isVisible: boolean) => onVisibilityChange(isVisible, start)} active={active} delayedCall>
					{React.cloneElement(RenderComponent, { ref: countUpRef })}
				</VisibilitySensor>
			)}
		</CountUp>
	)
}

interface Query {
	allContentfulAsset: {
		edges: {
			node: { gatsbyImageData: IGatsbyImageData }
		}[]
	}
}

const query = graphql`
	query {
		allContentfulAsset(filter: { title: { eq: "Rainbow Collage" } }) {
			edges {
				node {
					gatsbyImageData
				}
			}
		}
	}
`
