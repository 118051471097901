import { Button } from '@arckit/ui'
import { Section, Row, Col } from 'components/grid'
import { H2 } from 'components/typography/Headings'
import { P } from 'components/typography/Paragraph'
import { StaticImage } from 'gatsby-plugin-image'
import React from 'react'

export default function JoinOurWebinars() {
	return (
		<Section margin="mt-20 md:mt-40">
			<Row middle>
				<Col width="w-full sm:w-7/12" className="text-center sm:text-left">
					<div className="mx-auto mb-2 h-3 w-40 bg-ab-100 sm:mx-0"></div>
					<H2 className="mb-4">Join the Conversation</H2>
					<P className="mb-6 lg:mb-8">
						Discover unparalleled insights and engage with literacy pioneers and thought leaders by participating in our ongoing webinar series.
					</P>
					<div className="flex flex-wrap items-center justify-center gap-6 gap-y-4 sm:justify-start">
						<a href="https://info.americanreading.com/upcoming-webinar" className="hover:no-underline" target="_blank">
							<Button size="xl">Explore Upcoming Sessions</Button>
						</a>
						<a href="https://mailchi.mp/americanreading/webinar-recording-sign-up-form" target="_blank" className="text-gray-500 hover:text-ab-100 hover:no-underline">
							<div className="flex gap-2">
								<span className="text-gray-500">Revisit Previous Webinars</span>
								<div className="flex h-6 w-6 items-center justify-center rounded-full border border-ab-100">
									<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="h-3 w-3 text-ab-100">
										<path
											fillRule="evenodd"
											d="M4.5 5.653c0-1.426 1.529-2.33 2.779-1.643l11.54 6.348c1.295.712 1.295 2.573 0 3.285L7.28 19.991c-1.25.687-2.779-.217-2.779-1.643V5.653z"
											clipRule="evenodd"
										/>
									</svg>
								</div>
							</div>
						</a>
					</div>
				</Col>
				<Col width="w-5/12" className="hidden sm:block">
					<StaticImage src="../../../static/images/home/webinar.png" alt="webinar" />
				</Col>
			</Row>
		</Section>
	)
}
